import React from 'react';

import { useModal } from './ModalProvider';
import Modal from './Modal';

const ModalRouter = ({ children }: { children: React.ReactNode }) => {
  const { route, params, navigateToModal } = useModal();
  const isOpen = Boolean(route);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => navigateToModal('')}
      maxWidth={params?.maxWidth}
    >
      {children}
    </Modal>
  );
};

export default ModalRouter;
