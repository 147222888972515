import React from 'react';
import { useModal } from './ModalProvider';

type Props = {
  name: string;
  component: React.ReactNode;
};

const ModalRoute = ({ name, component }: Props) => {
  const { route } = useModal();

  if (route !== name) {
    return null;
  }

  return <>{component}</>;
};

export default ModalRoute;
