import { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';

interface Props {
  isOpen: boolean;
  maxWidth?: string;
  onClose: () => void;
  children: React.ReactNode;
}

export default function Example({ isOpen, onClose, children }: Props) {
  return (
    <Transition.Root show={isOpen} as={Fragment} appear>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20 my-auto h-fit">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className=" flex flex-col flex-1 h-96 overflow-hidden mx-auto max-w-3xl transform divide-y divide-gray-100  rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
