import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FormCard from '../../components/FormCard';
import { ChevronLeftIcon, TrashIcon, MailIcon } from '@heroicons/react/outline';
import { useForm, useModal, LoadingDim, Field } from '@posy/components';
import { getMainFields, getPermissionFields } from '../getFields';
import useLocations from 'hooks/useLocations';
import { useDefaultLocationProvider } from 'providers/DefaultLocationProvider';
import { useEffect, useState } from 'react';
import useFeatureFlags from 'hooks/useFeatureFlags';

const UserAccountForm = ({
  userAccount,
  onSubmit,
  onDelete,
  onPasswordReset,
  id,
  isLoading,
}: any) => {
  const { t } = useTranslation();
  const { enableEmployeeCustomLocation } = useFeatureFlags();
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { locations } = useLocations();
  const { defaultLocationId } = useDefaultLocationProvider();
  const [defaultValues, setDefaultValues] = useState(() =>
    getInitialValues(userAccount, defaultLocationId),
  );
  useEffect(() => {
    setDefaultValues(getInitialValues(userAccount, defaultLocationId));
  }, [userAccount, defaultLocationId]);

  const { registerField, handleSubmit } = useForm({ defaultValues });

  return (
    <div className="px-4 overflow-auto">
      <LoadingDim isLoading={isLoading} />
      <div className="flex justify-end p-4">
        <div className="flex-1">
          <button
            type="button"
            className="inline-flex text-2xl font-semibold text-gray-900"
            onClick={() => navigate('/userAccounts')}
          >
            <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
            {id ? t('editUserAccount') : t('newUserAccount')}
          </button>
        </div>

        <button
          className="bg-indigo-700 text-white font-medium rounded-md px-4 py-2"
          onClick={handleSubmit(onSubmit)}
        >
          {t('save')}
        </button>
      </div>
      <Wrapper>
        <div className="flex flex-col lg:flex-row gap-4">
          <FormCard title={t('userAccountData')}>
            {getMainFields(
              Boolean(id),
              locations,
              enableEmployeeCustomLocation,
            ).map((field) =>
              field.hidden && id ? null : (
                <Field key={field.name} {...registerField(field)} />
              ),
            )}
          </FormCard>
          <FormCard title={t('permissions.permissions')}>
            {getPermissionFields().map((field) => (
              <Field key={field.name} {...registerField(field)} />
            ))}
          </FormCard>
        </div>
      </Wrapper>
      <div className="flex justify-end  my-4">
        {id && (
          <ButtonContainer>
            <button
              className="flex items-center text-indigo-700 mb-2"
              onClick={onPasswordReset}
            >
              <MailIcon className="w-4 h-4 mr-2" />
              {t('sendRestorePWEmail')}
            </button>
            <button
              className="flex items-center text-red-700"
              onClick={() =>
                openModal({
                  title: t('modals.delete.title'),
                  body: t('modals.delete.body'),
                  onConfirm: onDelete,
                })
              }
            >
              <TrashIcon className="w-4 h-4 mr-2" />
              {t('deleteUserAccount')}
            </button>
          </ButtonContainer>
        )}
      </div>
    </div>
  );
};

const getInitialValues = (userAccount: any, defaultLocationId: string) => {
  return {
    ...(userAccount || {}),
    locationId: userAccount?.locationId || defaultLocationId,
  };
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const Wrapper = styled.div`
  grid-gap: 12px;
`;

export default UserAccountForm;
