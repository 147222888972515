import React from 'react';

interface ModalContextType {
  isOpen: any;
  route?: string;
  params?: any;
  openModal: () => void;
  closeModal: () => void;
  navigateToModal: (route: string, params?: any) => void;
}

const ModalRouterContext = React.createContext<ModalContextType>(null!);

const ModalRouterProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const [route, setRoute] = React.useState<string>('');
  const [params, setParams] = React.useState<any>({});
  const [routes, setRoutes] = React.useState<any>([]);

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  const navigateToModal = (route: string, params: any = {}) => {
    const found = routes.find(({ name }: { name: string }) => name === route);
    if (found) {
      setRoute(found.name);
      setParams({ ...(found.params || {}), ...(params || {}) });
    } else {
      setRoute(route);
      setParams(params);
      setRoutes((routes: any) => routes.concat({ name: route, params }));
    }
  };

  const value = {
    isOpen,
    route,
    params,
    navigateToModal,
    openModal,
    closeModal,
  };

  return (
    <ModalRouterContext.Provider value={value}>
      {children}
    </ModalRouterContext.Provider>
  );
};

function useModal() {
  return React.useContext(ModalRouterContext);
}

export { useModal };

export default ModalRouterProvider;
